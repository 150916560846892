import { useState, useEffect } from "react";

const useScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const hasScrolled = window.pageYOffset > 0;
    setIsScrolled(hasScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clears the event when dismantling
    };
  }, []); // The empty array ensures that the effect is only executed once (componentDidMount behavior)

  return isScrolled;
};

export default useScroll;
