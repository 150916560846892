import React from "react";
import ServiceItem from "./ServiceItem";

const ServicesList = ({ services }) => {
  return (
    <section className="service-details-two p-relative">
      <div className="container">
        <div className="row ">
          {services.map((service, index) => (
            <ServiceItem key={index} service={service} isActive={index === 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
