import React, { useState } from "react";

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://ostrya.com.br/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      alert(data.message);
    } catch (error) {
      alert("Erro ao enviar e-mail");
      console.error("Erro:", error);
    }
  };

  return (
    <div className="col-lg-5">
      <div className="contact-bg02">
        <div
          className="section-title wow fadeInDown animated"
          data-animation="fadeInDown"
          data-delay=".4s"
        >
          <h2>Fale Conosco</h2>
          <form
            method="post"
            className="contact-form mt-30 wow fadeInUp animated"
            data-animation="fadeInUp"
            data-delay=".4s"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-field p-relative c-name mb-20">
                  <input
                    type="text"
                    id="firstn"
                    name="firstName"
                    placeholder="Nome"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-field p-relative c-subject mb-20">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-field p-relative c-subject mb-20">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Número de telefone"
                    required=""
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-field p-relative c-message mb-30">
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    placeholder="Escreva sua pergunta."
                    required
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="slider-btn">
                  <button
                    className="btn ss-btn"
                    data-animation="fadeInRight"
                    data-delay=".8s"
                    type="submit"
                  >
                    <span>Enviar</span>
                    <i className="fal fa-long-arrow-right" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
