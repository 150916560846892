import React from "react";

const ListItem = ({ bullet, title, description }) => (
  <li className="media d-flex align-items-start mb-4">
    <div className="mr-3 px-4">
      <i className={`fas ${bullet} text-oc-secondary fa-2x`}></i>
    </div>
    <div className="media-body ml-3">
      {" "}
      {/* Adicionei ml-3 para garantir o espaçamento à esquerda */}
      <h5 className="mt-0 mb-1">{title}</h5>
      <span>{description}</span>
    </div>
  </li>
);

const ListColumn = ({ items }) => (
  <ul className="list-unstyled">
    {items.map((item, index) => (
      <ListItem
        key={index}
        bullet={item.bullet}
        title={item.title}
        description={item.description}
      />
    ))}
  </ul>
);

const Problems = () => {
  const firstColumnItems = [
    {
      bullet: "fa-exclamation-triangle",
      title: "Conflito de Interesses",
      description:
        "Nos grandes bancos, assessores ganham comissões por vender produtos específicos, o que pode não ser o melhor para você.",
    },
    {
      bullet: "fa-eye-slash",
      title: "Falta de Transparência",
      description:
        "As taxas e comissões muitas vezes não são claras, e você pode estar pagando mais do que deveria.",
    },
    {
      bullet: "fa-exchange-alt",
      title: "Troca Constante de Produtos",
      description:
        "Os bancos tradicionais e assessorias incentivam a troca frequente de produtos para gerar mais comissões.",
    },
  ];

  const secondColumnItems = [
    {
      bullet: "fa-bullseye",
      title: "Pressão por Metas de Vendas",
      description:
        "Assessorias e bancos são pressionados a bater metas de vendas, o que pode comprometer as recomendações feitas.",
    },
    {
      bullet: "fa-hand-holding-usd",
      title: "Foco em Produtos Próprios",
      description:
        "Bancos costumam priorizar produtos internos, que podem não ser as melhores opções para o seu perfil.",
    },
    {
      bullet: "fa-clock",
      title: "Falta de Acompanhamento",
      description:
        "Depois da venda, o suporte oferecido pelos grandes bancos costuma ser mínimo, deixando você sem orientação.",
    },
  ];

  return (
    <div
      id="description"
      className="animated wow fadeInUp description bg-white py-5"
    >
      <div className="container">
        <div className="row">
          <div className=" col-lg-12 text-left py-5 sect">
            <h2 className="text-center display-6 mb-5">
              Os Erros Comuns dos Bancos e Assessorias que <br />
              Podem Custar Caro
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ListColumn items={firstColumnItems} />
          </div>
          <div className="col-lg-6">
            <ListColumn items={secondColumnItems} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problems;
