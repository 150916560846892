import React from "react";
import { ABOUT_IMAGE, OSTRYA_ICON_01 } from "../../constants/images.c";

function About() {
  return (
    <>
      <section
        id="about"
        className="about-area about-p pt-120 pb-120 p-relative fix"
        style={{ background: "#eff7ff" }}
      >
        <div className="animations-02">
          <img src={OSTRYA_ICON_01} alt="contact-bg-an-01" />
        </div>

        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={ABOUT_IMAGE} alt="img" />
                <div className="about-text second-about">
                  <span>
                    10 <sub>+</sub>
                  </span>
                  <p>Anos de experiência no mercado financeiro</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>Sobre nós</h5>
                  <h2>Guiando Suas Escolhas Para o Melhor Caminho</h2>
                </div>
                <p className="txt-clr text-black fw-bold">
                  A Ostrya Investimentos é uma consultoria de investimentos
                  independente e comprometida em oferecer as melhores opções de
                  investimento sem receber comissões ou incentivos de corretoras
                  ou bancos. Nossa remuneração é transparente e negociada
                  diretamente com o investidor, garantindo um serviço alinhado
                  aos seus objetivos.
                </p>
                <p>
                  Inspirados na resistência da árvore Ostrya, conhecida como
                  Ironwood, acreditamos que, assim como as árvores, os
                  investimentos precisam de cuidado contínuo para crescerem e
                  darem frutos ao longo do tempo. Com mais de uma década de
                  experiência no mercado financeiro, nossa missão é ajudar cada
                  investidor a cultivar seus recursos de forma sólida, visando a
                  criação de riqueza a médio e longo prazo.
                </p>
                <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        {/* <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>01</span>
                            </div>{" "}
                            <div className="text">
                              <h3>Totalmente Independentes</h3>{" "}
                              <p>
                                Não recebemos comissões pelos seus investimentos
                              </p>
                            </div>
                          </div>
                        </li> */}
                        {/*<li>
                           <div className="abcontent">
                            <div className="ano">
                              <span>02</span>
                            </div>{" "}
                            <div className="text">
                              <h3>Totalmente Seguro</h3>{" "}
                              <p>Apenas você movimenta os valores investidos</p>
                            </div>
                          </div> 
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="slider-btn mt-20">
                  <a href="#event" className="btn ss-btn smoth-scroll">
                    Saiba Mais <i className="fal fa-long-arrow-right" />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
