import React from "react";
import { INDEPENDENCE_01 } from "../../constants/images.c";

function ConsultantIndependence() {
  return (
    <div className="container animated wow fadeInUp my-5">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
          <img
            src={INDEPENDENCE_01}
            alt="Imagem Descrição"
            className="img-fluid"
          />
        </div>

        <div className="col-lg-6 col-md-12">
          <h2>A importância de um Consultor Independente</h2>
          <p>
            Em muitos casos, o mesmo produto de investimento apresenta
            rendimentos diferentes em cada banco. Aqui, nosso{" "}
            <span className="fw-bold text-oc-secondary">
              consultor não está relacionado a bancos ou corretoras
            </span>{" "}
            , o que significa que você receberá uma análise imparcial e
            personalizada. Com total independência, os consultores examinam
            essas variações e garantem que você escolha a opção mais vantajosa,
            sempre de acordo com seu perfil e objetivos financeiros.
          </p>
          <div className="col-lg-4 ">
            <div
              className="cta-btn s-cta-btn wow fadeInRight animated "
              data-animation="fadeInDown animated"
            >
              <a
                href="https://wa.me/5551981646071?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20de%20investimentos"
                target="_blank"
                className="btn ss-btn smoth-scroll"
              >
                Entenda Melhor suas Opções de Investimento
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default ConsultantIndependence;
