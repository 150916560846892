import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Methodology from "../../components/Methodology/Method";
import Investments from "../../components/Investments/Investments";
import Frequently from "../../components/Frequently/Frequently";
import Planning from "../../components/Planning/Planning";
import Mentoring from "../../components/Mentoring/Mentoring";
import Problems from "../../components/Problems/Problems";
import Solutions from "../../components/Solutions/Solutions";
import Schedule from "../../components/Schedule/Schedule";
import Evaluation from "../../components/Evaluation/Evaluation";
import Importance from "../../components/Importance/ConsultantIndependence";
import NewsletterSection from "../../components/Newsletter/Newsletter";
import MultiFamily from "../../components/MultiFamily/MultiFamily";
import Modal from "../../components/Newsletter/Modal";

function Home() {
  const sectionsRef = useRef([]);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const sections = [
    { component: <Hero />, key: "slider" },
    { component: <About />, key: "about" },
    { component: <Solutions />, key: "solutions" },
    { component: <Importance />, key: "importance" },
    { component: <Methodology />, key: "method" },
    { component: <MultiFamily />, key: "multiFamily" },
    { component: <Problems />, key: "problems" },
    { component: <Evaluation />, key: "evaluation" },
    { component: <Mentoring />, key: "mentoring" },
    { component: <Planning />, key: "planning" },
    { component: <Investments />, key: "investments" },
    { component: <Frequently />, key: "frequently" },
    { component: <Schedule />, key: "schedule" },
    { component: <NewsletterSection />, key: "newsletter" },
  ];

  // Efeito para controlar o modal com sessionStorage
  useEffect(() => {
    const isModalShown = sessionStorage.getItem("isModalShown");

    if (!isModalShown) {
      // Exibe o modal pela primeira vez que o usuário acessar a página
      setShowModal(true);
      sessionStorage.setItem("isModalShown", "true"); // Define o status para "já mostrado"
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.style.opacity = 1;
              entry.target.classList.add("animated", "fadeInUp");
              observer.unobserve(entry.target);
            }
            entry.target.style.animationDuration = "3s"; // Aumenta a duração da animação
          });
        },
        { threshold: 0.1 }
      );

      sectionsRef.current.forEach((section) => {
        if (section) {
          section.style.opacity = 0; // Opacidade inicial
          observer.observe(section); // Observa as seções
        }
      });

      return () => {
        sectionsRef.current.forEach((section) => {
          if (section) observer.unobserve(section); // Limpa as observações
        });
      };
    } else {
      // Caso não esteja na Home, reseta a opacidade
      sectionsRef.current.forEach((section) => {
        if (section) {
          section.style.opacity = 1; // Mostra o conteúdo normalmente
        }
      });
    }
  }, [location.pathname]);

  return (
    <>
      {/* Modal que segue o scroll e cobre todas as seções */}
      <Modal showModal={showModal} setShowModal={setShowModal} />

      {sections.map((section, index) => (
        <div
          ref={(el) => (sectionsRef.current[index] = el)}
          key={section.key}
          className="hidden-section"
          style={{ transition: "opacity 0.5s ease-out" }}
        >
          {section.component}
        </div>
      ))}
    </>
  );
}

export default Home;
