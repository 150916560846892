import { WHATSAPP_LINK } from "../../constants/links.c";

export const Button = ({
  IconStyle = "",
  icon = "",
  buttonStyle = "",
  buttonText = "Fale Conosco",
}) => (
  <div>
    <a
      href={WHATSAPP_LINK}
      className={`btn ${buttonStyle}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className={`${icon} ${IconStyle}`} /> {buttonText}
    </a>
  </div>
);
