import React from "react";
import Slider from "react-slick";
import HeroSliderSettings from "./HeroSliderSettings";
import HeroContent from "./HeroContent";

const HeroSliderSection = () => {
  return (
    <section id="hero" className="slider-area fix p-relative">
      <Slider
        className="slider-active"
        style={{ background: "#141b22" }}
        {...HeroSliderSettings}
      >
        <HeroContent />
      </Slider>
    </section>
  );
};

export default HeroSliderSection;
