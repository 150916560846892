import React, { useState } from "react";

// Componente de Título
const Heading = () => {
  return (
    <h2 className="fs-2 fw-light mb-4">
      Inscreva-se na nossa{" "}
      <span className="fw-bold text-oc-secondary">Newsletter</span> e receba
      atualizações essenciais sobre o mercado financeiro.{" "}
    </h2>
  );
};

// Componente de Formulário de E-mail
const EmailForm = () => {
  const [value, setValue] = useState("");

  const formatNumber = (value) => {
    // Remove tudo que não for número e formata com separadores de milhar
    const number = value.replace(/\D/g, "");
    const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedNumber ? `R$ ${formattedNumber}` : "";
  };

  const handleValueChange = (e) => {
    const inputValue = e.target.value;
    setValue(formatNumber(inputValue));
  };

  return (
    <div className="grouped-inputs border bg-light p-3">
      <div className="row">
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="text"
              className="form-control p-3"
              id="floatingName"
              placeholder="Nome"
            />
            <label htmlFor="floatingName" className="py-2">
              Nome
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="tel"
              className="form-control p-3"
              id="floatingPhone"
              placeholder="Telefone"
            />
            <label htmlFor="floatingPhone" className="py-2">
              Telefone
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="text"
              className="form-control p-3"
              id="floatingValue"
              placeholder="Valor Aplicado"
              value={value}
              onChange={handleValueChange}
            />
            <label htmlFor="floatingValue" className="py-2">
              Valor Aplicado
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="email"
              className="form-control p-3"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInput" className="py-2">
              Endereço de email
            </label>
          </form>
        </div>
        <div className="col-12 col-md-4">
          <a href="#" className="btn btn-dark w-100 py-3 fw-bold">
            INSCREVA-SE
          </a>
        </div>
      </div>
    </div>
  );
};

const NewsletterSection = () => {
  return (
    <div className="container my-5" data-aos="fade" data-aos-delay="200">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-8 text-center">
          <Heading />
        </div>
        <div className="col-12 col-lg-8">
          <div className="row justify-content-center">
            <EmailForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection;
