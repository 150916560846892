import React from "react";

const calls = [
  {
    image: "icon fal fa-phone",
    description: "Fale Conosco",
    way: "tel:+",
    contact: "+55 51 981646071",
  },
  {
    image: "icon fal fa-envelope",
    description: "Email",
    way: "mailto:",
    contact: "investimentos@ostrya.com.br",
  },
];

const HeaderCTA = () => {
  return (
    <div className="header-cta">
      <ul>
        {calls.map((call, index) => (
          <li key={index}>
            <div className="call-box">
              <div className="icon">
                <i className={call.image}></i>
              </div>
              <div className="text">
                <span>{call.description}</span>
                <strong>
                  <a href={`${call.way}${call.contact}`}>{call.contact}</a>
                </strong>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeaderCTA;
