import React from "react";
import { MENTORING_IMAGE_01 } from "../../constants/images.c";

// Componente de Cabeçalho
const SectionTitle = () => {
  return (
    <div className="row g-5 mb-4 wow fadeInUp" data-wow-delay="0.1s">
      <div className="col-lg-6">
        <h2 className="section-title display-5 mb-0">Mentoria</h2>
      </div>
    </div>
  );
};

// Componente de Serviço Individual
const MentoringItem = ({ title, description, iconClass, delay }) => {
  return (
    <div className="col-lg-6 animated wow fadeInUp" data-wow-delay={delay}>
      <div className="service-item d-flex flex-column flex-sm-row bg-oc-primary rounded h-100 p-4 p-lg-5">
        <div className="bg-icon flex-shrink-0 mb-3">
          <i className={`fa ${iconClass} fa-2x text-oc-secondary`}></i>
        </div>
        <div className="ms-sm-4">
          <h4 className="mb-3 text-white">{title}</h4>
          <span className="text-white">{description}</span>
        </div>
      </div>
    </div>
  );
};

// Componente de Lista de Serviços
const MentoringList = () => {
  const mentoringData = [
    {
      title: "Desenvolvimento do Investidor",
      description:
        "Por meio de um programa de indicações de conteúdos, a mentoria visa aprimorar as habilidades técnicas e comportamentais do investidor. São recomendados cursos, livros, podcasts, vídeos e outros recursos exclusivos que permitem ao investidor expandir seu conhecimento e refinar suas estratégias de investimento.",
      iconClass: "fa-book-open", // Ícone para Desenvolvimento do Investidor
    },
    {
      title: "Estratégia Customizada",
      description:
        "A mentoria inclui a estruturação de um plano de investimentos personalizado, cuidadosamente elaborado para atender às suas necessidades individuais. Esse plano considera o perfil, os objetivos e as restrições de cada investidor.",
      iconClass: "fa-chart-line", // Ícone para Estratégia Customizada
    },
    {
      title: "Consultor Exclusivo",
      description:
        "O investidor terá acesso direto a um consultor dedicado, que acompanhará de perto o seu progresso. Com reuniões periódicas, esse consultor oferece suporte contínuo, ajustando estratégias conforme necessário e orientando o investidor em cada etapa do processo.",
      iconClass: "fa-user-tie", // Ícone para Consultor Exclusivo
    },
    {
      title: "Reuniões Online",
      description:
        "A mentoria também inclui encontros online regulares, individuais e em grupo, nos quais são discutidos temas técnicos sobre investimentos, análises de ativos e tendências de mercado. Esses encontros são essenciais para manter o investidor informado e preparado para tomar decisões estratégicas.",
      iconClass: "fa-video", // Ícone para Reuniões Online
    },
  ];

  return (
    <div className="row g-4">
      {mentoringData.map((mentoring, index) => (
        <MentoringItem
          key={index}
          title={mentoring.title}
          description={mentoring.description}
          iconClass={mentoring.iconClass}
        />
      ))}
    </div>
  );
};

// Componente Principal
const Mentoring = () => {
  return (
    <div
      className="container-fluid animated wow fadeInUp bg-white py-5 mb-5"
      id="service"
    >
      <div className="container">
        <div className="row align-items-center">
          {/* Cards à direita */}
          <div className="col-12 col-md-8">
            <SectionTitle />
            <MentoringList />
          </div>
          {/* Imagem à esquerda com placeholder */}
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <img
              src={MENTORING_IMAGE_01}
              alt="Placeholder"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentoring;
