import { SampleNextArrow, SamplePrevArrow } from "../common/SliderArrows";
const HeroSliderSettings = {
  autoplay: true,
  autoplaySpeed: 10000,
  dots: false,
  fade: true,
  arrows: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [{ breakpoint: 1200, settings: { dots: false, arrows: false } }],
};

export default HeroSliderSettings;
