import React from "react";
import HeaderLinks from "./HeaderLinks";

const MobileMenu = ({ mobile, setMobile }) => {
  return (
    <div className="col-12">
      <div className="mobile-menu mean-container">
        <div className="mean-bar">
          <a
            href="#nav"
            onClick={() => setMobile(!mobile)}
            className={`meanmenu-reveal ${mobile && "meanclose"}`}
            style={{
              right: 0,
              left: "auto",
              textAlign: "center",
              textIndent: 0,
              fontSize: 18,
            }}
          >
            {mobile ? (
              "X"
            ) : (
              <span>
                <span>
                  <span></span>
                </span>
              </span>
            )}
          </a>
          {mobile && (
            <nav className="mean-nav">
              <ul style={{ display: "block" }}>
                <HeaderLinks />
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
