import React from "react";
import {
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  WHATSAPP_LINK,
} from "../../constants/links.c";

const socialMedias = [
  { link: INSTAGRAM_LINK, title: "Instagram", icon: "fab fa-instagram" },
  { link: LINKEDIN_LINK, title: "LinkedIn", icon: "fab fa-linkedin" },
  { link: WHATSAPP_LINK, title: "WhatsApp", icon: "fab fa-whatsapp" },
];

const HeaderSocialLinks = () => {
  return (
    <div className="header-social">
      <span>
        {socialMedias.map((media, index) => (
          <a
            key={index}
            href={media.link}
            title={media.title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={media.icon} />
          </a>
        ))}
      </span>
    </div>
  );
};

export default HeaderSocialLinks;
