import React from "react";
import HeaderLinks from "./HeaderLinks";

const MainMenu = () => {
  return (
    <nav id="mobile-menu">
      <HeaderLinks />
    </nav>
  );
};

export default MainMenu;
