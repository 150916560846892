import React, { useState } from "react";
import Form from "../common/FormQuestions";

const faqItems = [
  {
    question: "Quem pode se beneficiar de uma consultoria de investimentos?",
    answer:
      "Qualquer pessoa ou empresa que deseja otimizar seus investimentos pode se beneficiar desse serviço. Isso inclui tanto investidores iniciantes, que precisam de orientação para começar, quanto investidores experientes, que buscam qualificar a forma como tomam decisões de investimentos. ",
  },
  {
    question: "Posso cancelar o serviço de consultoria a qualquer momento?",
    answer:
      "Sim, você pode cancelar o serviço de consultoria a qualquer momento, sem penalidades. A Ostrya Investimentos oferece total flexibilidade para garantir que você esteja satisfeito com o serviço prestado. Se em algum momento decidir que não deseja continuar, basta informar o consultor e o serviço será encerrado.",
  },
  {
    question: "Há algum custo de cancelamento?",
    answer:
      "Não, não há custos de cancelamento. Na Ostrya Investimentos, acreditamos que você deve ter total liberdade para encerrar o serviço se não estiver satisfeito ou se suas necessidades mudarem. O cancelamento é simples e direto, sem taxas adicionais ou obrigações contratuais de longo prazo.",
  },
  {
    question: "O que acontece se eu já tiver um portfólio de investimentos?",
    answer:
      "Se você já possui um portfólio, o consultor fará uma avaliação detalhada para determinar se ele está alinhado com seu perfil de investidor e seus objetivos. Caso necessário, serão recomendados ajustes para otimizar o desempenho do seu portfólio, garantindo que ele esteja bem posicionado para alcançar seus objetivos financeiros.",
  },
  {
    question: "Eu preciso seguir todas as recomendações do consultor?",
    answer:
      "Não, a decisão final sempre será sua. O consultor de investimentos fornece recomendações baseadas em uma análise cuidadosa do mercado e do seu perfil, mas você tem total controle sobre quais sugestões seguir e como implementar as estratégias recomendadas.",
  },
];

function Frequently() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <section className="faq-area pt-120 pb-120 p-relative fix">
        <div className="container">
          <div className="row justify-content-center  align-items-center">
            <div className="col-lg-7">
              <div
                className="section-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>Perguntas Frequentes</h2>
                <p>
                  Se você tiver perguntas adicionais ou precisar de mais
                  esclarecimentos, nossa equipe está pronta para ajudar. Navegue
                  pelas respostas abaixo para obter as informações que você
                  precisa.
                </p>
              </div>
              <div className="faq-wrap mt-30 pr-30">
                <div className="accordion" id="accordionExample">
                  {faqItems.map((item, index) => (
                    <div className="card" key={index}>
                      <div className="card-header" id={`heading${index}`}>
                        <h2 className="mb-0">
                          <button
                            className={`faq-btn${
                              activeIndex === index ? "" : " collapsed"
                            }`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${index}`}
                            onClick={() => handleToggle(index)}
                          >
                            {item.question}
                          </button>
                        </h2>
                      </div>
                      <div
                        id={`collapse${index}`}
                        className={`collapse${
                          activeIndex === index ? " show" : ""
                        }`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="card-body">{item.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Form />
          </div>
        </div>
      </section>
    </>
  );
}

export default Frequently;
