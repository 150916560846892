import React from "react";
import { Link } from "react-router-dom";

const HeaderLinks = () => {
  return (
    <ul>
      <li>
        <Link to="/">Início</Link>
      </li>
      <li>
        <Link to="/investimentos">Consultoria de Investimentos</Link>
      </li>
      <li>
        <Link to="/mentoria">Mentoria</Link>
      </li>
      <li>
        <Link to="/planejamento-sucessorio">Planejamento Sucessório</Link>
      </li>
    </ul>
  );
};

export default HeaderLinks;
