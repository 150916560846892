import React from "react";
import ServicesList from "../Services/ServicesList";
import {
  SERVICE_ICON_01,
  SERVICE_ICON_02,
  SERVICE_ICON_03,
} from "../../constants/images.c";

const heroServices = [
  {
    icon: SERVICE_ICON_01,
    title: "Consultoria de Investimentos",
    description:
      "Trabalhamos com diversos ativos, como títulos de renda fixa, ações, fundos, e derivativos, para clientes no Brasil e no exterior.",
    link: "/investimentos",
  },
  {
    icon: SERVICE_ICON_02,
    title: "Mentoria",
    description:
      "Acompanhamento direto com o consultor, abordando habilidades técnicas e comportamentais essenciais para decisões financeiras.",
    link: "/mentoria",
  },
  {
    icon: SERVICE_ICON_03,
    title: "Planejamento Sucessório",
    description:
      "Ajudamos a estruturar seu patrimônio, desde a escolha de investimentos até a criação de uma holding para facilitar a sucessão.",
    link: "/planejamento-sucessorio",
  },
];

const HeroServicesSection = () => {
  return (
    <section className="service-details-two p-relative">
      <div className="container">
        <ServicesList services={heroServices} />{" "}
      </div>
    </section>
  );
};

export default HeroServicesSection;
