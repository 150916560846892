import React from "react";
import { INVESTMENTS_IMAGE_05 } from "../../../constants/images.c";

function Fifth() {
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="upper-box">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img src={INVESTMENTS_IMAGE_05} alt="" />
              </figure>
            </div>
          </div>

          <div className="lower-content2">
            <div className="row">
              <div className="text-column col-lg-9 col-md-12 col-sm-12">
                <div
                  className="s-about-content wow fadeInRight"
                  data-animation="fadeInRight"
                  data-delay=".2s"
                >
                  <h2>
                    Renda fixa: Como Aproveitar Juros Altos e Proteger Seu
                    Capital
                  </h2>
                  <p>
                    Os investimentos em renda fixa, geralmente, correspondem por
                    uma parcela relevante de um portfólio, seja dos investidores
                    mais conservadores, como, também, dos investidores mais
                    arrojados.{" "}
                    <strong>
                      Manter recursos em títulos de renda fixa de alta qualidade
                      proporciona a obtenção de retornos com baixa volatilidade.{" "}
                    </strong>{" "}
                    Nos mercados desenvolvidos, os títulos de renda fixa de alta
                    qualidade apresentam retorno baixo, muitas vezes mantendo
                    apenas o poder de compra do capital, outras vezes, nem isso.
                  </p>
                  <p>
                    Já no Brasil, a história é diferente.{" "}
                    <strong>
                      Historicamente o Brasil apresenta uma taxa real de juros
                      elevada
                    </strong>{" "}
                    que, por um lado, mostra-se como um peso para o setor
                    produtivo e para a dívida pública, mas, por outro lado,
                    apresenta-se como uma{" "}
                    <strong>oportunidade para os investidores</strong>. Em 2024,
                    por exemplo, as taxas de juros reais dos títulos públicos
                    brasileiros indexados à inflação, de médio e longo prazo,
                    oscilam entre 6,0 a 6,5%a.a. Nada mal para compor um
                    portfólio diversificado, com horizonte de investimento de
                    longo prazo.
                  </p>
                  <p>
                    Além de aproveitar uma taxa de juros real elevada no mercado
                    brasileiro, destacam-se as seguintes vantagens potenciais
                    que{" "}
                    <strong>
                      os títulos de renda fixa podem agregar a um portfólio de
                      investimentos:
                    </strong>
                  </p>

                  <ol>
                    <li>
                      <strong>Diversificação</strong> <br />
                      <p>
                        Os títulos de renda fixa costumam ter uma baixa
                        correlação em relação à outras classes de ativos, como
                        os títulos de renda variável. Em geral, espera-se um
                        retorno e uma volatilidade baixa para os títulos de
                        renda fixa, em especial aqueles com mais qualidade (high
                        grade). Assim, os investidores podem utilizar os títulos
                        de renda fixa como um amortecedor para momentos de
                        desvalorização das ações, mantendo recursos que podem
                        ser utilizados em períodos de oportunidades no mercado
                        como, por exemplo, o investimento em ativos
                        desvalorizados. Por outro lado, o investidor pode
                        aproveitar para reforçar o portfólio de renda fixa,
                        quando estiver reduzindo a exposição a ativos mais
                        voláteis.
                      </p>
                    </li>
                    <li>
                      <strong>Fluxo de caixa regular</strong> <br />
                      <p>
                        Os diversos títulos de renda fixa disponíveis têm uma
                        característica em comum, que é o estabelecimento das
                        regras de pagamento no momento da sua emissão. Assim, o
                        investidor saberá, na emissão do título, a estrutura de
                        remuneração, o prazo de vencimento, o fluxo de pagamento
                        e eventuais garantias. A partir dessas características
                        poderíamos citar títulos com formatos diversos, no que
                        se refere a taxa de juros (prefixada, pós-fixada e pré +
                        pós-fixada), fluxo de caixa (pagamento único e ou
                        periódico) e garantias (com ou sem). Assim, ao realizar
                        o investimento, se saberá o fluxo de pagamento prometido
                        pelo título, possibilitando que o investidor estruture
                        um portfólio com perfil de vencimento desejado e
                        alinhado com as suas necessidades de caixa.
                      </p>
                    </li>
                    <li>
                      <strong>Potencial proteção contra a inflação</strong>{" "}
                      <br />
                      <p>
                        Alguns títulos de renda fixa são indexados a índices de
                        inflação, proporcionando, além de uma taxa de juros
                        real, a proteção inflacionária calculada a partir do
                        índice escolhido. Por exemplo, existem títulos públicos
                        emitidos pelo Governo brasileiro, que remuneram o IPCA
                        acrescido de uma taxa de juros real. Assim, esse título
                        garante o reajuste do valor investido pelo índice
                        oficial de inflação da economia brasileira, além de uma
                        remuneração real. Por outro lado, títulos de renda fixa
                        prefixados estão mais expostos ao risco de oscilação na
                        taxa de juros e no nível de preços.
                      </p>
                    </li>
                    <li>
                      <strong>Potencial proteção contra a inflação</strong>
                      <br />
                      <p>
                        O investimento em ações de grandes empresas pode ser uma
                        boa forma de se proteger contra a desvalorização da
                        moeda ao longo do tempo. Isso acontece, pois, grandes
                        empresas costumam ter melhores condições de repassar os
                        custos para os seus produtos/serviço, protegendo as suas
                        margens e geração de caixa, mesmo em momentos de
                        inflação elevada. Assim, o investimento em ações pode
                        contribuir para a manutenção do poder de compra do
                        capital ao longo do tempo.{" "}
                      </p>
                    </li>
                    <li>
                      <strong>Liquidez</strong>
                      <br />
                      <p>
                        Alguns títulos de renda fixa proporcionam liquidez
                        diária aos investidores, o que possibilitam a obtenção
                        de rentabilidade com a vantagem de manter recursos com
                        liquidez para serem utilizados quando necessário. Os
                        títulos públicos, negociados no Tesouro Direto são um
                        exemplo. Contudo, outros títulos de renda fixa possuem
                        resgate apenas no vencimento, ou ao longo do tempo, com
                        as amortizações periódicas. Nesses casos, a liquidez
                        pode ser obtida a partir de negociações no mercado
                        financeiro, geralmente no chamado mercado de balcão, com
                        as corretoras de valores.{" "}
                      </p>
                    </li>
                  </ol>

                  <p>
                    Ao contrário do que muitas vezes se pensa, os títulos de
                    renda fixa também apresentam riscos, sendo que os principais
                    são: risco de taxa de juros e risco de crédito.
                  </p>
                  <p>
                    O risco de taxa de juros está mais presente em títulos com
                    remuneração prefixada, ou títulos com remuneração pré +
                    pós-fixada, ficando mais evidente em cenários de oscilação
                    na taxa de juros de mercado. No caso dos títulos prefixados,
                    como o investidor saberá exatamente qual será a sua taxa de
                    juros até o vencimento do título, um cenário de elevação na
                    taxa de juros pode implicar em perdas no preço de mercado do
                    título. Já uma conjuntura de queda nas taxas de juros de
                    mercado, podem implicar em valorização no preço de mercado
                    do título.
                  </p>
                  <p>
                    Já o risco de crédito advém do risco de inadimplência nas
                    condições previstas na emissão do título. Os investidores
                    podem negociar títulos públicos diretamente com o Tesouro
                    Nacional, a partir do site Tesouro Direto (TD). Uma vez que
                    se trata de títulos emitidos pelo Governo Federal, possuem
                    um risco de crédito muito baixo (risco de default da dívida
                    pública). O TD também garante a liquidez diária dos títulos.
                    Assim, trata-se de uma possibilidade de investimento com
                    baixos custos operacionais e que proporciona aos
                    investidores o acesso à títulos públicos com características
                    diversas, tais como: Tesouro Selic, Tesouro Pré-fixado,
                    Tesouro IPCA+, Tesouro IPCA+ com juros semestrais e outros.
                  </p>
                  <p>
                    Além dos títulos públicos, também é possível investir em
                    títulos de renda fixa emitidos por instituições financeiras
                    ou empresas privadas. Contudo, destaca-se que esses títulos
                    possuem <strong>risco de crédito</strong>, ou seja, risco de
                    que o emissor não cumpra com as condições previstas na sua
                    emissão. Uma vez que o investidor está assumindo um risco
                    adicional aos títulos públicos, espera-se que esses títulos
                    apresentem uma remuneração superior, que é chamado de{" "}
                    <strong>prêmio pelo risco de crédito</strong>. Quanto maior
                    for o risco de inadimplência (default) do emissor, maior
                    será o retorno exigido em relação aos títulos públicos com
                    vencimento similar. Existem diversos tipos de títulos
                    privados, que serão detalhados em outro artigo, mas podemos
                    citar como principais: CDB, LF, LCA, LCIs, Debêntures e
                    outros.
                  </p>
                  <p>
                    Alguns títulos bancários, como o CDB, LCA, LCI e outros,
                    possuem cobertura do Fundo Garantidor de Crédito (FGC), que
                    mitiga o risco de não pagamento aos investidores em caso de
                    default do emissor. Além disso, quando se fala em risco de
                    crédito, é importante considerar a aplicação via fundos de
                    investimento, considerando a possibilidade de diversificação
                    do risco. Em outros artigos vamos detalhar mais sobre os
                    diversos tipos de títulos públicos e privados de renda fixa,
                    bem como, sobre os veículos de investimentos como os fundos.
                  </p>
                  <p>
                    Em síntese, o investimento em títulos de renda fixa
                    apresenta uma série de detalhes, proporcionando diferentes
                    expectativas de retorno, a depender do risco e nível de
                    liquidez que esteja sendo assumido. Assim, destaca-se a
                    importância de que o investidor tenha um acompanhamento
                    profissional para que possa tomar as decisões mais adequadas
                    ao seu perfil. Neste sentido, a{" "}
                    <strong>Ostrya Investimentos</strong> possui profissionais
                    tecnicamente qualificados e com larga experiência no mercado
                    financeiro para estruturar o portfólio de investimentos mais
                    adequado ao investidor e acompanhá-lo nessa jornada no
                    mercado financeiro.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fifth;
