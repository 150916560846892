import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "../pages/HomePage/Home";
import Footer from "../components/Footer/Footer";
import Investments from "../components/Investments/Investments";
import InvestmentDetails from "../components/Investments/InvestmentsDetails";
import Header from "../components/Header/Header";
import About from "../components/About/About";
import Mentoring from "../components/Mentoring/Mentoring";
import Planning from "../components/Planning/Planning";

function Index() {
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre-nos" element={<About />} />
        <Route path="/mentoria/" element={<Mentoring />} />
        <Route path="/investimentos/" element={<Investments />} />
        <Route
          path="/investimentos/:investmentId"
          element={<InvestmentDetails />}
        />
        {/* <Route path="/politicas/" element={<Policies />} /> */}
        <Route path="/planejamento-sucessorio/" element={<Planning />} />
      </Routes>
      <Footer />
    </>
  );
}

export default Index;
