import React from "react";
import { INVESTMENTS_IMAGE_04 } from "../../../constants/images.c";

function Fourth() {
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="upper-box">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img src={INVESTMENTS_IMAGE_04} alt="" />
              </figure>
            </div>
          </div>

          <div className="lower-content2">
            <div className="row">
              <div className="text-column col-lg-9 col-md-12 col-sm-12">
                <div
                  className="s-about-content wow fadeInRight"
                  data-animation="fadeInRight"
                  data-delay=".2s"
                >
                  <h2>
                    Ações: Como Se Tornar Sócio das Maiores Empresas do Brasil{" "}
                  </h2>
                  <p>
                    Você gostaria de ser sócio das melhores empresas
                    brasileiras? Isso é possível a partir do investimento em
                    ações na bolsa de valores.
                  </p>
                  <p>
                    O número de investidores na bolsa de valores brasileira
                    disparou ~10 vezes ao longo dos últimos anos, saindo de 500
                    mil pessoas em 2011 para 5,0 milhões em 2024, segundo dados
                    da B3. A popularização do investimento em renda variável é
                    importante e faz parte da maturação do mercado de capitais
                    brasileiro, contudo, trata-se de um movimento que deve ser
                    feito de forma consciente e com educação dos investidores,
                    para que saibam sobre os riscos e oportunidades dessa
                    modalidade.
                  </p>
                  <p>
                    Primeiramente,{" "}
                    <strong>
                      é fundamental que o investidor conheça o seu perfil
                    </strong>{" "}
                    e siga os seus objetivos respeitando as suas restrições em
                    relação aos investimentos. Muitos investidores não têm
                    perfil para o investimento em renda variável e não há
                    problema nenhum nisso. Existem ótimas opções de
                    investimentos para atender aos perfis mais conservadores.
                    Para aqueles investidores que tem o perfil para alocação em
                    ativos mais voláteis, como é o caso das ações, é importante,
                    antes de qualquer decisão, conhecer o funcionamento e a
                    dinâmica do mercado.
                  </p>
                  <p>
                    <strong>
                      Investir em ações é, antes de mais nada, investir em
                      participações minoritárias em empresas.
                    </strong>{" "}
                    Dois aspectos importantes dessa modalidade de investimento
                    são a volatilidade e a liquidez. A volatilidade diz respeito
                    às oscilações de preços que as ações apresentam diariamente,
                    de acordo com a oferta e a demanda de mercado pelos títulos.
                    Já a liquidez diz respeito à possibilidade dessas
                    participações poderem ser negociadas diariamente.{" "}
                    <strong>
                      No curto prazo, os preços das ações podem sofrer
                      influência de inúmeros fatores, como: fluxo de capital
                      financeiro, fatores políticos, movimentos especulativos,
                      expectativas sobre a economia e sobre o desempenho da
                      empresa, entre outros. Contudo, no médio e longo prazo, o
                      preço das ações será influenciado, principalmente, pelo
                      desempenho econômico-financeiro da empresa.
                    </strong>{" "}
                    Neste sentido, o investimento em ações é de longo prazo,
                    mesmo que esses títulos possam ser negociados diariamente.
                  </p>
                  <p>
                    <strong>
                      Se um empresário mantém as participações na sua empresa ao
                      longo de anos, muitas vezes ao longo de décadas, por que
                      um investidor deveria negociar as suas ações em poucos
                      meses ou dias?
                    </strong>{" "}
                    Tenha muito cuidado com incentivos e recomendações que
                    tenham como principal objetivo gerar negociações e
                    corretagens, ao invés de buscar incentivar as melhores
                    decisões para o seu portfólio.
                  </p>
                  <p>
                    Claro que{" "}
                    <strong>
                      um fator importante a se destacar é o preço que se paga
                      pelo ativo.
                    </strong>{" "}
                    Por vezes, o investidor pode ter um desempenho ruim pagando
                    caro por ações de uma boa empresa e um bom desempenho
                    pagando barato por uma empresa mediana. Contudo, deixaremos
                    esses aspectos mais técnicos para outro artigo. Aqui, o
                    objetivo é falarmos sobre como as ações podem contribuir
                    para um portfólio de investimentos.
                  </p>
                  <p>
                    Neste sentido, podemos citar 4 contribuições potenciais da
                    alocação de recursos em ações:
                  </p>

                  <ol>
                    <li>
                      <strong>Diversificação</strong> <br />
                      <p>
                        O investimento em ações contribui para a diversificação
                        do portfólio de investimentos, uma vez que os seus
                        retornos costumam não ter correlação elevada com outros
                        ativos, como os títulos de renda fixa. Por exemplo, em
                        momentos de queda nas taxas de juros, que implicam em
                        redução nas taxas de retorno de títulos de renda fixa
                        pós-fixados, os ativos de renda variável tendem a
                        performar bem. Por outro lado, momentos de queda nos
                        preços dos ativos de renda variável, podem ser
                        contrabalanceados por ativos mais defensivos, como os
                        títulos de renda fixa de alta qualidade (high grade).
                      </p>
                    </li>
                    <li>
                      <strong>Apreciação do capital</strong> <br />
                      <p>
                        No médio e longo prazo, desde que não se cometa muitos
                        erros relevantes, o portfólio de ações tende a performar
                        melhor do que o portfólio de renda fixa. No mercado esse
                        retorno adicional é chamado de prêmio pelo risco
                        acionário (equity risk premium), ou seja, a taxa de
                        retorno acima da taxa livre de risco, para que compense
                        o investidor por assumir o risco de investir em um ativo
                        com maior risco. A FGV estima o prêmio de risco para o
                        mercado acionário brasileiro desde a década de 90. Nos
                        últimos 10 anos esse prêmio médio é estimado em
                        9,80%a.a. Assim, pode-se esperar um retorno médio de
                        9,80%a.a. superior a taxa de juros livre de risco,
                        medida pelos títulos norte-americanos de 10 anos
                        (T-notes), para assumir o risco de investir em um
                        portfólio diversificado de ações brasileiras. Claro que,
                        para esperar esse retorno adicional, o investidor deve
                        estar preparado para uma maior volatilidade de preços.{" "}
                      </p>
                    </li>
                    <li>
                      <strong>Recebimento de proventos</strong> <br />
                      <p>
                        As ações, uma vez que são participações minoritárias em
                        empresas, distribuem periodicamente proventos aos seus
                        acionistas. Esses proventos podem ser na modalidade de
                        dividendos ou juros sobre o capital próprio. Quanto
                        maior for o resultado e a geração de caixa livre da
                        empresa, maior poderá ser essa distribuição de proventos
                        e, também, a própria valorização do ativo no longo
                        prazo. Por outro lado, empresas com prejuízos não
                        distribuirão proventos e tendem a ver os preços das suas
                        ações desvalorizando ao longo do tempo. Desta forma é
                        importante reforçar que avaliar os fundamentos das
                        empresas e considerar o preço que se paga por elas, é
                        fundamental.
                      </p>
                    </li>
                    <li>
                      <strong>Potencial proteção contra a inflação</strong>
                      <br />
                      <p>
                        O investimento em ações de grandes empresas pode ser uma
                        boa forma de se proteger contra a desvalorização da
                        moeda ao longo do tempo. Isso acontece, pois, grandes
                        empresas costumam ter melhores condições de repassar os
                        custos para os seus produtos/serviço, protegendo as suas
                        margens e geração de caixa, mesmo em momentos de
                        inflação elevada. Assim, o investimento em ações pode
                        contribuir para a manutenção do poder de compra do
                        capital ao longo do tempo.{" "}
                      </p>
                    </li>
                  </ol>

                  <p>
                    Em resumo, o investimento em ações apresenta oportunidades e
                    riscos. Os investidores devem considerar o seu perfil,
                    objetivos e restrições antes de realizar qualquer alocação
                    de recursos e contar com profissionais pode ajudar a
                    qualificar essa decisão. Neste sentido, a{" "}
                    <strong>Ostrya Investimentos</strong>, uma consultoria
                    independente, conta com uma equipe tecnicamente qualificada,
                    com profissionais com mais de 12 anos de experiência em
                    investimentos no mercado financeiro, inclusive no mercado
                    acionário e que estão aptos para auxiliar você na tomada de
                    decisões sobre o seu portfólio de investimentos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fourth;
