import React from "react";
import { Button } from "../common/Button";

const ScheduleButton = () => {
  return (
    <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
      <div className="login">
        <ul>
          <li>
            <div className="second-header-btn">
              <Button icon={"fab fa-whatsapp"} buttonText={"Fale Conosco"} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ScheduleButton;
