import React from "react";
import { EVALUATION_01 } from "../../constants/images.c";

const HotelInfo = () => {
  return (
    <div className="container-xxl py-5 px-0 animated fadeInUp wow zoomIn">
      <div className="row g-0">
        <div className="col-md-6 bg-oc-primary d-flex align-items-center">
          <div className="p-5">
            <h6 className="section-title text-start text-white text-uppercase mb-3">
              Avaliação de Portfólio
            </h6>
            <h2 className="text-white mb-4">
              Solicite uma Avaliação Gratuita do Seu Portfólio
            </h2>
            <p className="text-white mb-4">
              A Ostrya Investimentos realiza uma análise gratuita do seu
              portfólio, ajudando você a aproveitar as melhores oportunidades e
              avaliar o que é mais adequado para os seus objetivos financeiros.
            </p>
            <a href="#" className="btn py-md-3 px-md-5 me-3">
              Agende Agora sua Avaliação
            </a>
          </div>
        </div>
        <div className="col-md-6">
          <ImageSection />
        </div>
      </div>
    </div>
  );
};

const ImageSection = () => {
  return (
    <div className="image-container">
      <img src={EVALUATION_01} alt="Avaliação Gratuita" className="img-fluid" />
    </div>
  );
};

const App = () => {
  return (
    <div>
      <HotelInfo />
    </div>
  );
};

export default App;
