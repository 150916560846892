import React from "react";

const ServiceItem = ({ service, isActive }) => (
  <div className="col-lg-4 col-md-12 col-sm-12">
    <div className={`services-box07 ${isActive ? "active" : ""}`}>
      <div className="sr-contner">
        <div className="icon">
          <img src={service.icon} alt={service.title} />
        </div>
        <div className="text">
          <h5>
            <a href={service.link}>{service.title}</a>
          </h5>
          <p>{service.description}</p>
        </div>
      </div>
      <a className="service-btn text-center text-white" href={service.link}>
        Saiba Mais <i className="fal fa-long-arrow-right" />
      </a>
    </div>
  </div>
);

export default ServiceItem;
