import React from "react";
import { WHATSAPP_LINK } from "../../constants/links.c";

function Schedule() {
  return (
    <>
      <section className="bg-sky cta-area pt-50 pb-50">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8">
              <div
                className="section-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>Agende uma reunião com nossos especialistas</h2>
                <p className="py-4">
                  Garanta o futuro do seu patrimônio e o crescimento dos seus
                  investimentos com o suporte de nossos consultores
                  especializados. Em uma reunião personalizada, vamos avaliar
                  suas necessidades, discutir as melhores estratégias de
                  investimento e apresentar um planejamento sucessório eficaz e
                  alinhado aos seus objetivos.
                </p>
              </div>
            </div>
            <div className="col-lg-4 text-right">
              <div
                className="cta-btn s-cta-btn wow fadeInRight animated "
                data-animation="fadeInDown animated"
              >
                <a
                  href="https://wa.me/5551981646071?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20de%20investimentos"
                  target="_blank"
                  className="btn ss-btn smoth-scroll"
                >
                  AGENDE AGORA <i className="fal fa-long-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Schedule;
