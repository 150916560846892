import React from "react";
import { METHOD_01 } from "../../constants/images.c";

// Componente de Cabeçalho
const Header = () => {
  return (
    <div className="row g-5 mb-4 wow fadeInUp" data-wow-delay="0.1s">
      <div className="col-lg-6">
        <h2 className="section-title display-5 mb-0">Metodologia</h2>
      </div>
    </div>
  );
};

// Componente de Serviço Individual
const ServiceItem = ({ title, description, iconClass, delay }) => {
  return (
    <div className="col-lg-6 wow fadeInUp" data-wow-delay={delay}>
      <div className="service-item d-flex flex-column flex-sm-row bg-oc-primary rounded h-100 p-4 p-lg-5">
        <div className="bg-icon flex-shrink-0 mb-3">
          <i className={`fa ${iconClass} fa-2x text-oc-secondary`}></i>
        </div>
        <div className="ms-sm-4">
          <h4 className="mb-3 text-white">{title}</h4>
          <span className="text-white">{description}</span>
        </div>
      </div>
    </div>
  );
};

// Componente de Lista de Serviços
const ServiceList = () => {
  const services = [
    {
      title: "Análise Inicial do Perfil e Portfólio do Investidor",
      description: "Entendemos suas metas e tolerância ao risco.",
      iconClass: "fa-user-tie",
      delay: "0.1s",
    },
    {
      title: "Estruturação do Portfólio",
      description:
        "Estruturação de portfólio adequado ao perfil do investidor.",
      iconClass: "fa-chart-line",
      delay: "0.3s",
    },
    {
      title: "Implementação e Acompanhamento",
      description:
        "Acompanhamento da implementação do plano e do desempenho do portfólio.",
      iconClass: "fa-tasks",
      delay: "0.1s",
    },
    {
      title: "Reavaliação e Rebalanceamento",
      description: "Mantemos sua carteira ajustada às suas metas.",
      iconClass: "fa-sync-alt",
      delay: "0.3s",
    },
  ];

  return (
    <div className="row g-4">
      {services.map((service, index) => (
        <ServiceItem
          key={index}
          title={service.title}
          description={service.description}
          iconClass={service.iconClass}
          delay={service.delay}
        />
      ))}
    </div>
  );
};

// Componente Principal
const Services = () => {
  return (
    <div
      className="container-fluid animated wow fadeInUp bg-white py-5 mb-5"
      id="service"
    >
      <div className="container">
        <div className="row align-items-center">
          {/* Imagem à esquerda com placeholder */}
          <div className="col-12 col-md-4 mb-4 mb-md-0">
            <img src={METHOD_01} alt="Placeholder" className="img-fluid" />
          </div>
          {/* Cards à direita */}
          <div className="col-12 col-md-8">
            <Header />
            <ServiceList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
