import React from "react";
import { SOLUTION_IMAGE } from "../../constants/images.c";

const solutionData = [
  {
    title: "Consultoria Independente",
    description:
      "Atuamos sem vínculo com corretoras ou instituições financeiras, garantindo recomendações imparciais focadas apenas nos seus interesses.",
  },
  {
    title: "Atendimento Personalizado",
    description:
      "Oferecemos acompanhamento contínuo e ajustamos seu portfólio conforme suas metas e perfil, garantindo resultados alinhados às suas expectativas.",
  },
  {
    title: "Remuneração Fee Based",
    description:
      "Nosso modelo fee based assegura que nossos interesses estejam sempre alinhados aos seus, com total transparência e sem comissões ocultas.",
  },
  {
    title: "Rebate e Benefícios",
    description:
      "Trabalhamos com corretoras parceiras que oferecem cashback aos nossos clientes, maximizando seus ganhos sem comprometer nossa independência.",
  },
];

const Solution = ({ title, description }) => (
  <div className="col-sm-6 wow fadeIn">
    <div className="d-flex align-items-center mb-3">
      <i className="fa fa-check fa-2x text-oc-secondary flex-shrink-0 me-3"></i>
      <h6 className="mb-0">{title}</h6>
    </div>
    <span>{description}</span>
  </div>
);

const SolutionImage = () => (
  <div className="col-lg-6 wow fadeInUp">
    <div
      className="position-relative overflow-hidden ps-5 pt-5 h-100"
      style={{ minHeight: "400px" }}
    >
      <img
        className="position-absolute w-100 h-100"
        src={SOLUTION_IMAGE}
        alt=""
        style={{ objectFit: "cover" }}
      />
      {/* <div
        className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
        style={{ width: "200px", height: "200px" }}
      >
        <div className="d-flex flex-column justify-content-center text-center bg-oc-primary h-100 p-3">
          <h1 className="text-white">+10</h1>
          <h2 className="text-white">ANOS</h2>
          <h5 className="text-white mb-0">
            DE EXPERIÊNCIA NO MERCADO FINANCEIRO
          </h5>
        </div>
      </div> */}
    </div>
  </div>
);

const SolutionContent = () => (
  <div className="col-lg-6 wow fadeInUp">
    <div className="border-start border-5 border-oc-primary ps-4 mb-5">
      <h6 className="text-body text-uppercase mb-2">POR QUE NOS ESCOLHER?</h6>
      <h2 className="display-6 mb-0">Soluções Financeiras Sob Medida</h2>
    </div>
    <p className="mb-5">
      Consultoria independente, transparência total e atendimento personalizado
      para o crescimento seguro do seu patrimônio.
    </p>
    <div className="row gy-5 gx-4">
      {solutionData.map((feature, index) => (
        <Solution
          key={index}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  </div>
);

const SolutionsSection = () => (
  <div className="animated fadeInUp container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <SolutionContent />
        <SolutionImage />
      </div>
    </div>
  </div>
);

export default SolutionsSection;
