import React from "react";

export function SampleNextArrow({ className, onClick }) {
  return (
    <button
      type="button"
      className={`slick-next ${className}`}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <i className="far fa-angle-right"></i>
    </button>
  );
}

export function SamplePrevArrow({ className, onClick }) {
  return (
    <button
      type="button"
      className={`slick-prev ${className}`}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <i className="far fa-angle-left"></i>
    </button>
  );
}
