export const LOGO_IMAGE = require("../assets/img/logo/logo.png");

// SLIDER
export const SLIDER_IMAGE_01 = require("../assets/img/slider/hero-01.png");

export const OSTRYA_ICON_01 = require("../assets/img/icons/bg-02.png");
export const OSTRYA_ICON_02 = require("../assets/img/bg/ostrya-white-vertical.png");

export const ABOUT_IMAGE = require("../assets/img/about-us/about.png");

export const INVESTMENTS_IMAGE_01 = require("../assets/img/investments/investments-01.png");
export const INVESTMENTS_IMAGE_02 = require("../assets/img/investments/investments-02.png");
export const INVESTMENTS_IMAGE_03 = require("../assets/img/investments/investments-03.png");
export const INVESTMENTS_IMAGE_04 = require("../assets/img/investments/investments-04.png");
export const INVESTMENTS_IMAGE_05 = require("../assets/img/investments/investments-05.png");

export const MENTORING_IMAGE_01 = require("../assets/img/mentoring/mentoring-01.png");

export const SOLUTION_IMAGE = require("../assets/img/solutions/solution.png");

export const SERVICE_ICON_01 = require("../assets/img/icons/sve-icon5.png");
export const SERVICE_ICON_02 = require("../assets/img/icons/sve-icon4.png");
export const SERVICE_ICON_03 = require("../assets/img/icons/sve-icon6.png");

// PLANNING
export const FAMILY_IMAGE = require("../assets/img/bg/family.png");
export const PLANNING_ICON_01 = require("../assets/img/icons/fea-icon01.png");
export const PLANNING_ICON_02 = require("../assets/img/icons/fea-icon02.png");
export const PLANNING_ICON_03 = require("../assets/img/icons/fea-icon03.png");

export const POLICIE_01 = require("../assets/documents/codigo-de-etica.pdf");
export const POLICIE_02 = require("../assets/documents/politica-de-compliance.pdf");
export const POLICIE_03 = require("../assets/documents/politica-de-investimentos.pdf");
export const POLICIE_04 = require("../assets/documents/politica-de-prevencao-a-lavagem-de-dinheiro.pdf");
export const POLICIE_05 = require("../assets/documents/politica-de-seguranca-da-informacao.pdf");
export const POLICIE_06 = require("../assets/documents/formulario-de-referencia.pdf");

export const INDEPENDENCE_01 = require("../assets/img/independence/independence.png");

export const EVALUATION_01 = require("../assets/img/evaluation/evaluation-01.jpeg");

export const STRATEGY_01 = require("../assets/img/strategy/strategy.png");
// export const MENTORING_IMAGE
// import image01 from "../../assets/img/mentoring/mentoring-01.png";
// import image02 from "../../assets/img/mentoring/mentoring-02.png";
// import image03 from "../../assets/img/mentoring/mentoring-03.png";
// import image04 from "../../assets/img/mentoring/mentoring-04.png";

export const MULTIFAMILY_01 = require("../assets/img/multifamily/multifamily-01.jpg");
export const MULTIFAMILY = require("../assets/img/multifamily/multifamily.jpg");
export const METHOD_01 = require("../assets/img/method/method-01.png");
