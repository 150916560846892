import React from "react";
import {
  FAMILY_IMAGE,
  OSTRYA_ICON_02,
  PLANNING_ICON_01,
  PLANNING_ICON_02,
  PLANNING_ICON_03,
} from "../../constants/images.c";

function Planning() {
  const features = [
    {
      icon: PLANNING_ICON_01,
      title: "Minimiza conflitos",
      description:
        "Reduz disputas entre herdeiros e garante que a vontade do titular seja cumprida.",
    },
    {
      icon: PLANNING_ICON_02,
      title: "Reduz impostos",
      description: "Diminui a carga tributária sobre a herança.",
    },
    {
      icon: PLANNING_ICON_03,
      title: "Simplifica processos",
      description:
        "Evita burocracias na transferência de bens e garente a continuidade de negócios.",
    },
  ];

  return (
    <>
      <section
        id="planning"
        className=" my-5 bg-planning steps-area p-relative"
      >
        <div className="animations-10">
          <img src={OSTRYA_ICON_02} alt="an-img-01" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="section-title mb-35 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h2>Planejamento Sucessório</h2>
                <p>
                  A Ostrya Investimentos conta com uma equipe especializada
                  pronta para auxiliar em todas as etapas do planejamento
                  sucessório, garantindo que a distribuição de bens ocorra
                  conforme os desejos do titular e de forma eficiente.
                </p>
              </div>
              <ul className="pr-20">
                {features.map((feature) => (
                  <li key={feature.icon}>
                    <div
                      className="step-box wow fadeInUp animated"
                      data-animation="fadeInUp"
                      data-delay=".4s"
                    >
                      <div className="dnumber">
                        <div className="date-box">
                          <img src={feature.icon} alt="icon" />
                        </div>
                      </div>
                      <div className="text">
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src={FAMILY_IMAGE} alt="Familia Reunida" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Planning;
