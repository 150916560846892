import React from "react";
import { INVESTMENTS_IMAGE_02 } from "../../../constants/images.c";

function Second() {
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="upper-box">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img src={INVESTMENTS_IMAGE_02} alt="" />
              </figure>
            </div>
          </div>

          <div className="lower-content2">
            <div className="row">
              <div className="text-column col-lg-9 col-md-12 col-sm-12">
                <div
                  className="s-about-content wow fadeInRight"
                  data-animation="fadeInRight"
                  data-delay=".2s"
                >
                  <h2>
                    Transparência e Alinhamento: Como Funciona a Remuneração de
                    um Consultor de Investimentos{" "}
                  </h2>
                  <p>
                    A remuneração de um consultor de investimentos é estruturada
                    para assegurar que o interesse do cliente esteja sempre em
                    primeiro lugar. Os consultores de investimentos operam de
                    maneira independente, sem receber comissões de produtos
                    financeiros, sendo remunerados diretamente pelo cliente a
                    partir das condições acordadas entre as partes. Essa
                    independência mitiga potenciais conflitos de interesse,
                    possibilitando que as recomendações sejam baseadas no que é
                    melhor para o cliente.
                  </p>
                  <p>
                    A remuneração de um consultor de investimentos pode ser
                    estruturada de diferentes maneiras, adaptando-se às
                    necessidades do cliente:
                    <ol>
                      <li>
                        <strong>Valor Fixa:</strong> Muitos consultores optam
                        por cobrar um valor fixo periódico pelo serviço de
                        consultoria. Este valor é acordado antecipadamente e
                        permanece constante, independentemente do desempenho dos
                        investimentos. Esse modelo oferece previsibilidade e
                        transparência tanto para o cliente quanto para o
                        consultor.
                      </li>
                      <li>
                        <strong>Percentual sobre o Patrimônio:</strong> Outro
                        modelo comum é a cobrança de um percentual sobre o valor
                        total das aplicações financeiras sob consultoria. Essa
                        taxa, que geralmente varia entre 0,5% e 1,5% ao ano,
                        está diretamente relacionada ao crescimento do
                        patrimônio do cliente. Quanto mais o portfólio do
                        cliente cresce, maior é a remuneração do consultor,
                        alinhando assim os interesses de ambos.
                      </li>
                      <li>
                        <strong>Taxa de Performance:</strong> Para os
                        investidores profissionais, a legislação permite a
                        cobrança de uma taxa de performance, que é um percentual
                        sobre os ganhos obtidos que superam um benchmark ou
                        retorno mínimo acordado. Esse modelo incentiva o
                        consultor a buscar continuamente as melhores
                        oportunidades de investimento para superar a taxa de
                        retorno exigida pelo cliente.
                      </li>
                    </ol>
                  </p>

                  <p>
                    Assim, uma das vantagens de contratar um consultor de
                    investimentos é a transparência. O cliente sabe exatamente
                    quanto está pagando e por quais serviços está pagando. Além
                    disso, o consultor está sempre focado em estratégias que
                    promovam o crescimento do patrimônio do cliente, já que seu
                    próprio sucesso financeiro está diretamente ligado ao
                    sucesso do cliente.
                  </p>

                  <p>
                    Esse modelo não só promove a confiança e a transparência,
                    mas também garante que as decisões de investimento sejam
                    sempre tomadas com o melhor interesse do cliente em mente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Second;
