import React from "react";
import { INVESTMENTS_IMAGE_03 } from "../../../constants/images.c";

function Third() {
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="upper-box">
            <div className="single-item-carousel owl-carousel owl-theme">
              <figure className="image">
                <img src={INVESTMENTS_IMAGE_03} alt="" />
              </figure>
            </div>
          </div>

          <div className="lower-content2">
            <div className="row">
              <div className="text-column col-lg-9 col-md-12 col-sm-12">
                <div
                  className="s-about-content wow fadeInRight"
                  data-animation="fadeInRight"
                  data-delay=".2s"
                >
                  <h2>
                    Aposentadoria: Como R$ 1.000 Mensais Podem Render Mais de R$
                    1 Milhão
                  </h2>
                  <p>
                    Não é segredo nenhum que a previdência social enfrenta
                    muitos desafios em relação à sua sustentabilidade, tendo
                    encerrado 2023 com um{" "}
                    <strong>déficit de R$ 306,0 bilhões</strong>, segundo o
                    Governo Federal. Assim, torna-se cada vez mais importante
                    que as famílias brasileiras planejem a sua aposentadoria sem
                    depender da previdência social. Contudo, segundo a
                    Fenaprevi,{" "}
                    <strong>
                      apenas 7,0% da população brasileira possui plano de
                      previdência privada em 2024.
                    </strong>
                  </p>
                  <p>
                    A formação de patrimônio financeiro para que seja utilizado
                    no futuro como fonte de renda, seja única, principal ou
                    complementar, depende de fatores como{" "}
                    <strong>disciplina</strong>, <strong>tempo</strong> e{" "}
                    <strong>decisões adequadas</strong>. A{" "}
                    <strong>disciplina</strong> de reservar parte da renda de
                    forma recorrente para as aplicações financeiras é
                    fundamental para acelerar o processo de acumulação de
                    capital. Conforme será apresentado mais adiante, pequenos
                    valores poupados mensalmente e aplicados a taxas de retorno
                    adequadas, podem representar somas representativas no
                    futuro. O <strong>tempo</strong> é outra variável
                    importante, uma vez que, quanto maior for o tempo de
                    investimento, maior será o efeito dos juros compostos.
                    Assim, é importante começar o mais cedo possível. Além
                    disso, tomar as <strong>decisões adequadas</strong> de
                    alocação do capital poupado é fundamental, pois nada adianta
                    ter a disciplina de poupar e perder esses recursos em
                    investimentos malsucedidos. Os investimentos quem impliquem
                    em perdas financeiras, que apresentem baixo retorno e/ou que
                    impliquem em custos operacionais elevados, vão tornar mais
                    difícil e mais lenta a formação de um capital relevante no
                    longo prazo. Assim, os recursos devem ser investidos em
                    ativos que oferecem a melhor relação risco-retorno esperada
                    considerando o perfil de cada investidor.{" "}
                  </p>
                  <strong>Vamos à algumas simulações.</strong> <br />
                  <p>
                    Considerando uma taxa de juros real, ou seja, acima da
                    inflação, já líquida de imposto de renda, de 5,30%a.a., que
                    hoje é possível obter com títulos públicos indexados ao
                    IPCA, um investimento mensal de R$ 1.000,00, somaria:
                  </p>
                  <p>R$ 156,7 mil em 10 anos</p>
                  <p>R$ 419,5 mil em 20 anos</p>
                  <p>R$ 859,8 mil em 30 anos</p>
                  <p>
                    Lembrando que estou falando em termos reais, ou seja, acima
                    da inflação do período.{" "}
                  </p>
                  <p>
                    Agora, caso o investidor tenha algum apetite por risco e
                    faça uma alocação, de parte da carteira, em risco de
                    crédito, obtendo, digamos, 6,50%a.a. de retorno no seu
                    portfólio como um todo, o mesmo aporte mensal de R$ 1.000,00
                    somaria:
                  </p>
                  <p>R$ 166,7 mil em 10 anos</p>
                  <p>R$ 479,6 mil em 20 anos</p>
                  <p>R$ 1,067 milhão em 30 anos</p>
                  <p>
                    Assim, assumindo-se algum tipo de risco, é possível acumular
                    pouco mais de R$ 1,0 milhão, com aportes de R$ 1,0 mil/mês
                    ao longo de 30 anos.{" "}
                  </p>
                  <p>
                    Agora, para investidores que aceitam assumir alguma
                    volatilidade maior, alocando parte dos recursos em renda
                    variável, pode-se esperar um retorno real maior, digamos de
                    8,0% a.a., assim, R$ 1.000,00 mensais alcançariam:
                  </p>
                  <p>R$ 180,1 mil em 10 anos</p>
                  <p>R$ 569,0 mil em 20 anos</p>
                  <p>R$ 1,409 milhão em 30 anos</p>
                  <p>
                    Como dito anteriormente, a <strong>disciplina</strong>, o{" "}
                    <strong>tempo</strong> e as{" "}
                    <strong> decisões adequadas</strong> sobre a alocação, são
                    fundamentais para o sucesso na construção de riqueza ao
                    longo do tempo.{" "}
                  </p>
                  <p>
                    O mercado financeiro apresenta um amplo leque de opções de
                    investimento e{" "}
                    <strong>
                      tomar a decisão sobre onde alocar os recursos não é uma
                      tarefa fácil para quem não é um especialista.
                    </strong>
                    Primeiramente, é fundamental{" "}
                    <strong>avaliar o perfil do investidor</strong>, processo
                    que irá possibilitar o autoconhecimento por parte do
                    investidor e, também, proporcionar que o consultor de
                    investimentos também conheça o seu cliente e identifique as
                    opções mais adequadas. Os objetivos, restrições,
                    conhecimento, apetite ao risco, necessidade de liquidez e
                    outros fatores importantes sobre o cliente serão avaliados
                    nesse processo.
                  </p>
                  <p>
                    Cada perfil de investidor terá uma alocação mais adequada,
                    de forma que clientes mais conservadores, terão portfólios
                    com menor risco e volatilidade, mas, também, com menor
                    retorno esperado. Já clientes mais arrojados, terão
                    portfólios com alguma parcela de risco e volatilidade, mas,
                    também, com um maior retorno esperado. É importante destacar
                    que, não há certo ou errado em relação ao perfil do
                    investidor, mas há pessoas e famílias com objetivos e
                    necessidades distintas, cujas características devem ser
                    respeitadas no processo de investimento na busca pela
                    criação de riqueza ao longo do tempo.
                  </p>
                  <p>
                    Para auxiliar o investidor neste processo é fundamental o
                    apoio de profissionais tecnicamente capacitados, experientes
                    e que tenham os interesses alinhados com os seus clientes.
                    Neste sentido, a <strong>Ostrya Investimentos</strong>, uma
                    consultoria independente, conta com soluções financeiras
                    completas para todos os perfis de clientes e é focada na
                    criação de riqueza e relacionamento de longo prazo com os
                    investidores. Entre em contato com um consultor da{" "}
                    <strong>Ostrya Investimentos</strong> e venha saber como
                    podemos lhe ajudar a construir riqueza ao longo do tempo
                    através de investimentos no mercado financeiro.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Third;
