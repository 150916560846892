import React from "react";
import { SLIDER_IMAGE_01 } from "../../constants/images.c";
import { Button } from "../common/Button";

const HeroBackground = ({ children }) => (
  <div
    className="single-slider slider-bg"
    style={{
      backgroundImage: `url(${SLIDER_IMAGE_01})`,
      backgroundSize: "cover",
    }}
  >
    {children}
  </div>
);

const HeroText = () => (
  <>
    <h5 data-animation="fadeInUp" data-delay=".4s">
      Bem-vindo à Ostrya
    </h5>
    <h2 data-animation="fadeInUp" data-delay=".4s">
      Cuidamos do seu patrimônio com transparência e independência
    </h2>
    <p data-animation="fadeInUp" data-delay=".6s">
      A Ostrya Investimentos é uma consultoria independente comprometida em
      oferecer as melhores opções de investimento para o seu perfil.
    </p>
  </>
);

const HeroContent = () => (
  <>
    <HeroBackground>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-7">
            <div className="slider-content s-slider-content mt-130">
              <HeroText />
              <div className="mt-4">
                <Button
                  icon="fab fa-whatsapp"
                  IconStyle="px-2"
                  buttonText={"Fale Agora Com um Consultor"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroBackground>
  </>
);

export default HeroContent;
