import React from "react";
import { Link } from "react-router-dom";
import {
  POLICIE_01,
  POLICIE_02,
  POLICIE_03,
  POLICIE_04,
  POLICIE_05,
  POLICIE_06,
} from "../../constants/images.c";

// Componente para a seção de contato
const ContactSection = () => {
  return (
    <div className="footer-widget mb-30">
      <div className="f-widget-title">
        <h2>Fale Conosco</h2>
      </div>
      <div className="f-contact">
        <ul>
          <li>
            <i className="icon fal fa-phone" />
            <span>
              <Link to="tel:+5551981646071">+55 51 98164-6071</Link>
            </span>
          </li>
          <li>
            <i className="icon fal fa-envelope" />
            <span>
              <Link to="mailto:investimentos@ostrya.com.br">
                investimentos@ostrya.com.br
              </Link>
            </span>
          </li>
          <li>
            <i className="icon fal fa-map-marker-check" />
            <span>
              R. Tamandaré, 140, sala 901, - Novo Hamburgo - RS, 93410-150
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

// Componente para a seção de políticas
const PoliciesSection = ({ policies }) => {
  return (
    <div className="footer-widget mb-30">
      <div className="f-widget-title">
        <h2>Políticas Corporativas</h2>
      </div>
      <ul>
        {policies.map((policy, index) => (
          <li key={index}>
            <a href={policy.file} target="_blank" rel="noopener noreferrer">
              {policy.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

// Componente para a seção de citações e redes sociais
const QuoteSection = () => {
  return (
    <div className="footer-widget mb-30">
      <div className="f-contact">
        <p>
          <em>
            "Se alguém está sentado à sombra hoje é porque uma outra pessoa
            plantou uma árvore um dia"
          </em>
          <br />- Warren Buffett
        </p>
      </div>
      <div className="footer-social mt-10">
        <a
          href="https://www.instagram.com/ostrya.investimentos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
        <a
          href="https://wa.me/5551981646071?text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20consultoria%20de%20investimentos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp" />
        </a>
        <a
          href="https://www.linkedin.com/company/ostrya-investimentos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin" />
        </a>
      </div>
    </div>
  );
};

// Componente principal que une todas as seções do footer
function Main() {
  const policies = [
    { name: "Código de Ética", file: POLICIE_01 },
    {
      name: "Política de Investimentos",
      file: POLICIE_03,
    },
    { name: "Política de Compliance", file: POLICIE_02 },
    {
      name: "Política de Segurança da Informação",
      file: POLICIE_05,
    },
    {
      name: "Política de Prevenção à Lavagem de Dinheiro",
      file: POLICIE_04,
    },
    {
      name: "Formulário de Referência",
      file: POLICIE_06,
    },
  ];

  return (
    <>
      <footer
        className="footer-bg footer-p pt-90"
        style={{
          backgroundColor: "#013220",
        }}
      >
        <div className="footer-top pb-70">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <QuoteSection />
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-6">
                <PoliciesSection policies={policies} />
              </div>

              <div className="col-xl-4 col-lg-4 col-sm-6">
                <ContactSection />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 text-center">
                <div className="copy-text">
                  Copyright © Ostrya 2024. Todos os direitos reservados.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
