import React from "react";
import HeroSliderSection from "./HeroSliderSection";
import HeroServicesSection from "./HeroServicesSections";

const Hero = () => {
  return (
    <>
      <HeroSliderSection />
      <HeroServicesSection />
    </>
  );
};

export default Hero;
