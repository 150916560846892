import React from "react";
import { useParams } from "react-router-dom";
import First from "./investment-content/First";
import Second from "./investment-content/Second";
import Third from "./investment-content/Third";
import Fourth from "./investment-content/Fourth";
import Fifth from "./investment-content/Fifth";

function InvestmentsDetails() {
  const { investmentId } = useParams();

  console.log("Valor de investmentId:", investmentId); // Verifique no console se está capturando corretamente

  const renderInvestmentsDetails = () => {
    switch (investmentId) {
      case "diferenca-de-consultoria-e-assessoria":
        return <First />;
      case "transparencia-e-alinhamento-entenda-a-remuneracao":
        return <Second />;
      case "aposentadoria-como-rs-1000-mensais-podem-render-mais-de-1-milhao":
        return <Third />;
      case "acoes-como-se-tornar-socio":
        return <Fourth />;
      case "renda-fixa-aproveitar-juros-altos":
        return <Fifth />;
      default:
        return;
    }
  };

  return <>{renderInvestmentsDetails()}</>;
}

export default InvestmentsDetails;
