import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LOGO_IMAGE } from "../../constants/images.c";
import SocialLinks from "./HeaderSocialLinks";
import HeaderCTA from "./HeaderCTA";
import MainMenu from "./HeaderMainMenu";
import ScheduleButton from "./HeaderScheduleButton";
import MobileMenu from "./HeaderMobileMenu";
import useScroll from "../../hooks/useScroll"; // Hook customizado para detectar scroll

const Main = () => {
  const [mobile, setMobile] = useState(false);
  const isScrolled = useScroll(); // Usando o hook customizado para monitorar o scroll

  return (
    <header className="header-area header-three">
      <div className="header-top second-header d-none d-md-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 d-none d-lg-block">
              <SocialLinks />
            </div>
            <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
              <HeaderCTA />
            </div>
          </div>
        </div>
      </div>
      <div
        id="header-sticky"
        className={`menu-area${isScrolled ? " sticky-menu" : ""}`}
      >
        <div className="container">
          <div className="second-menu">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2">
                <div className="logo">
                  <Link to="/">
                    <img src={LOGO_IMAGE} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 text-right">
                <div className="main-menu">
                  <MainMenu />
                </div>
              </div>
              <ScheduleButton />
              <MobileMenu mobile={mobile} setMobile={setMobile} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Main;
