import React from "react";

// Componente de Título
const Heading = () => {
  return (
    <h2 className="fs-2 fw-light mb-4">
      Inscreva-se na nossa{" "}
      <span className="fw-bold text-oc-secondary">Newsletter</span> e receba
      atualizações essenciais sobre o mercado financeiro.{" "}
    </h2>
  );
};

// Componente de Formulário de E-mail
const EmailForm = () => {
  const [value, setValue] = React.useState("");

  const formatNumber = (value) => {
    const number = value.replace(/\D/g, "");
    const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedNumber ? `R$ ${formattedNumber}` : "";
  };

  const handleValueChange = (e) => {
    const inputValue = e.target.value;
    setValue(formatNumber(inputValue));
  };

  return (
    <div className="grouped-inputs border bg-light p-3">
      <div className="row">
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="text"
              className="form-control p-3"
              id="floatingName"
              placeholder="Nome"
            />
            <label htmlFor="floatingName" className="py-2">
              Nome
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="tel"
              className="form-control p-3"
              id="floatingPhone"
              placeholder="Telefone"
            />
            <label htmlFor="floatingPhone" className="py-2">
              Telefone
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="text"
              className="form-control p-3"
              id="floatingValue"
              placeholder="Valor Aplicado"
              value={value}
              onChange={handleValueChange}
            />
            <label htmlFor="floatingValue" className="py-2">
              Valor Aplicado
            </label>
          </form>
        </div>
        <div className="col-12 mb-3">
          <form className="form-floating">
            <input
              type="email"
              className="form-control p-3"
              id="floatingInput"
              placeholder="name@example.com"
            />
            <label htmlFor="floatingInput" className="py-2">
              Endereço de email
            </label>
          </form>
        </div>
        <div className="col-12 col-md-4">
          <a href="#" className="btn btn-dark w-100 py-3 fw-bold">
            INSCREVA-SE
          </a>
        </div>
      </div>
    </div>
  );
};

// Componente de Modal
const Modal = ({ showModal, setShowModal }) => {
  if (!showModal) return null;

  return (
    <div
      className="modal-overlay"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        backdropFilter: "blur(8px)", // Aplica efeito borrado ao fundo
        overflowY: "auto", // Modal segue o scroll
      }}
    >
      <div
        className="modal-content bg-white p-4"
        style={{
          maxWidth: "600px", // Aumenta a largura máxima
          width: "95%", // Garante que a largura seja responsiva
          minHeight: "600px", // Define uma altura mínima maior
          maxHeight: "90vh", // Altura máxima para não ultrapassar a tela
          borderRadius: "8px",
          overflowY: "auto", // Permite scroll se o conteúdo for muito grande
        }}
      >
        <button
          onClick={() => setShowModal(false)}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            fontSize: "1.5rem",
            cursor: "pointer",
          }}
        >
          &times;
        </button>
        {/* Conteúdo do pop-up */}
        <Heading />
        <EmailForm />
      </div>
    </div>
  );
};

export default Modal;
