import React from "react";
import { Link } from "react-router-dom";
import {
  INVESTMENTS_IMAGE_01,
  INVESTMENTS_IMAGE_02,
  INVESTMENTS_IMAGE_03,
  INVESTMENTS_IMAGE_04,
  INVESTMENTS_IMAGE_05,
  OSTRYA_ICON_01,
} from "../../constants/images.c";

function InvestmentItem({ link, image, title, description }) {
  return (
    <div
      className="col-lg-4 col-md-6 wow fadeInUp animated"
      data-animation="fadeInUp"
      data-delay=".4s"
    >
      <div className="event-item mb-30 hover-zoomin">
        <div className="thumb">
          <Link to={link}>
            <img src={image} alt={title} />
          </Link>
        </div>
        <div className="event-content">
          <h3>
            <Link to={link}>{title}</Link>
          </h3>
          <p>{description}</p>
          <div className="time">
            <Link to={link}>
              <i className="fal fa-long-arrow-right" />{" "}
              <strong>Saiba mais</strong>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function InvestmentList() {
  const investmentsData = [
    {
      link: "/investimentos/diferenca-de-consultoria-e-assessoria",
      image: INVESTMENTS_IMAGE_01,
      title: "Consultoria x Assessoria: Entenda a Diferença",
      description:
        "Conheça as principais diferenças entre consultoria e assessoria de investimentos.",
    },
    {
      link: "/investimentos/transparencia-e-alinhamento-entenda-a-remuneracao",
      image: INVESTMENTS_IMAGE_02,
      title: "Transparência e Alinhamento: Entenda a Remuneração",
      description:
        "Como funciona a remuneração de um consultor de investimentos.",
    },
    {
      link: "/investimentos/aposentadoria-como-rs-1000-mensais-podem-render-mais-de-1-milhao",
      image: INVESTMENTS_IMAGE_03,
      title: "Aposentadoria: Investindo para o futuro",
      description:
        "Entenda como R$ 1.000 Mensais Podem Render Mais de R$ 1 Milhão",
    },
    {
      link: "/investimentos/acoes-como-se-tornar-socio",
      image: INVESTMENTS_IMAGE_04,
      title: "Ações: Como Se Tornar Sócio das Maiores Empresas do Brasil",
      description:
        " Você gostaria de ser sócio das melhores empresas brasileiras? Entenda como isso é possível.",
    },
    {
      link: "/investimentos/renda-fixa-aproveitar-juros-altos",
      image: INVESTMENTS_IMAGE_05,
      title: "Como Aproveitar Juros Altos e Proteger Seu Capital",
      description:
        "Descubra como a renda fixa pode oferecer estabilidade e rentabilidade.",
    },
  ];

  return (
    <div className="row">
      {investmentsData.map((item, index) => (
        <InvestmentItem
          key={index}
          link={item.link}
          image={item.image}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
  );
}

function Investments() {
  return (
    <>
      <section id="investments" className="event pt-120 pb-90 p-relative fix">
        <div className="animations-07">
          <img src={OSTRYA_ICON_01} alt="contact-bg-an-01" />
        </div>

        <div className="animations-09">
          <img src={OSTRYA_ICON_01} alt="contact-bg-an-01" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-relative">
              <div
                className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>Insights de Investimento</h5>
              </div>
            </div>
          </div>

          <InvestmentList />
        </div>
      </section>
    </>
  );
}

export default Investments;
